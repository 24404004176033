<template>
  <div class="theme-public">
    <v-system-message></v-system-message>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <a href="/" plain text inline>
              <v-logo :show-big="true" width="200px"></v-logo>
            </a>
          </v-col>
        </v-row>
      </v-container>
      <v-fade-transition name="fade" mode="out-in">
        <router-view :class="$utilities.appClass(this.$route)" />
      </v-fade-transition>
    </v-main>
  </div>
</template>

<script>
  export default {
    name: 'Themeless.vue',
  };
</script>

<style lang="scss">
  .theme-public{
    height: 100%;
    background: {
      color: #f5f5f5;
    }
    .v-main{
      padding: 0 !important;
    }
  }
</style>